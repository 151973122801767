import React, { useState } from "react";
import { Select } from "antd";

const { Option } = Select;

const Navbar = ({ organization, onOptionChange }) => {

  const handleOptionSelect = (option) => {
    onOptionChange(option);
  };

  return (
    <nav
      className="menuBar"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className="logo">
        <a href="#">
          <img
            src="/logo@2x.png"
            className="image"
            alt="Logo"
            style={{ width: 200 }}
          />
        </a>
      </div>

      <div>
        <Select
          placeholder="เลือก Org"
          style={{ width: "100%" }}
          onChange={(value) => handleOptionSelect(value)}
        >
          {organization.map((option, index) => (
            <Option key={index} value={option.orgCustomId}>
              {option.orgCustomId}
            </Option>
          ))}
        </Select>
      </div>
    </nav>
  );
};

export default Navbar;
