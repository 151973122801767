import React, { useState } from "react";
import { Select, Card, Row, Col } from "antd";

const { Option } = Select;
const { Meta } = Card;

const ImageList = ({ searchResults }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  // const [searchResults, setSearchResults] = useState([]);

  // const handleSearch = () => {
  //   // Implement your search logic here based on the selected option
  //   // You might want to make an API request to the backend for search results
  //   console.log(`Search triggered for option: ${selectedOption}`);
  // };

  // const handleSearch = async (value) => {
  //   try {
  //     const response = await axios.get(`/api/search?q=${value}`);
  //     setSearchResults(response.data);
  //   } catch (error) {
  //     console.error("Error fetching search results:", error);
  //   }
  // };

  return (
    <>
      <div style={{ padding: "1.5rem", backgroundColor: "#323D47" }}>
        <Row gutter={[24, 24]}>
          {searchResults.map((result) => (
            <Col key={result.fileId} xs={24} sm={12} md={12} lg={12} xl={12}>
              <Card
                hoverable
                cover={
                  <img alt={result.vehicleLicense} src={result.presignedUrl} />
                }
              >
                <Card.Meta
                  title={result.vehicleBrand}
                  description={`ทะเบียน: ${result.vehicleLicense}  ${result.vehicleProvince}`}
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default ImageList;
