import React, { useEffect, useState } from "react";
import { Layout, Pagination, Input, notification } from "antd";
import ImageList from "./ImageList";
import "./App.css";
import Navbar from "./Components/Navbar/index";
import { Content } from "antd/es/layout/layout";
import axios from "axios";

const { Header } = Layout;
const { Search } = Input;

const Home = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [pageSize, setPageSize] = useState(6); // Default page size
  const [offset, setOffset] = useState(0); // Initial offset

  const [organization, setOrganization] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [textSearch, setTextSearch] = useState("");

  useEffect(() => {
    fetchImages();
    const checkTokenExpiration = () => {
      console.log("---> checkTokenExpiration");
      if (Date.now() >= parseInt(localStorage.getItem("expires_at"))) {
        refreshAccessToken();
      }
    };
    const intervalId = setInterval(checkTokenExpiration, 60 * 1000);
    return () => clearInterval(intervalId);
  }, [selectedOrg]);

  const refreshAccessToken = async () => {
    console.log("---> refreshAccessToken");
    const getNewAccessTokenRes = await axios.get("/getNewAccessToken", {
      params: {
        refreshToken: localStorage.getItem("refresh_token"),
      },
    });

    console.log("Response from getNewAccessToken:", getNewAccessTokenRes.data);

    const newAccessToken = getNewAccessTokenRes.data.access_token;
    localStorage.setItem("access_token", newAccessToken.access_token);
    localStorage.setItem("refresh_token", newAccessToken.refresh_token);
    localStorage.setItem("expires_at", newAccessToken.expires_in);
  };

  const fetchImages = async () => {
    try {
      // Get token from promjodd which is refresh token
      // Renew refresh token to get new access token
      // Use new access token for lpr
      const token = new URLSearchParams(window.location.search).get("token");
      if (!token) {
        notification.error({
          message: "Error",
          description: "Invalid token",
        });
      }

      const email = new URLSearchParams(window.location.search).get("email");

      const getOrgRes = await axios.get("/getOrg", {
        params: {
          email: email,
        },
      });
      console.log("Response from getOrg:", getOrgRes.data);
      setOrganization(getOrgRes.data);

      const getNewAccessTokenRes = await axios.get("/getNewAccessToken", {
        params: {
          refreshToken: token,
        },
      });

      console.log(
        "Response from getNewAccessToken:",
        getNewAccessTokenRes.data
      );

      const newAccessToken = getNewAccessTokenRes.data.access_token;
      localStorage.setItem("access_token", newAccessToken.access_token);
      localStorage.setItem("refresh_token", newAccessToken.refresh_token);
      localStorage.setItem("expires_at", newAccessToken.expires_in);

      const getImagesRes = await axios.get("/getImages", {
        params: {
          token: localStorage.getItem("access_token"),
          org: selectedOrg === null ? "default" : selectedOrg,
          limit: pageSize,
          offset: offset,
        },
      });

      console.log("Response from getImages:", getImagesRes.data);
      setSearchResults(getImagesRes.data);

      if (getImagesRes?.data.length === 0) {
        notification.error({
          message: "Error",
          description: "ไม่พบข้อมูล",
        });
      }

      const getImagesCountRes = await axios.get("/getImagesCount", {
        params: {
          token: localStorage.getItem("access_token"),
          org: selectedOrg === null ? "default" : selectedOrg,
        },
      });
      setTotalResults(getImagesCountRes.data);
    } catch (error) {
      console.log(error?.response?.data?.message);

      if (error?.response?.data?.message === "Token is not active") {
        notification.error({
          message: "Error",
          description: "Invalid token. Please login again.",
        });
      }
    }
  };

  const handleSearch = async (value) => {
    try {
      console.log(value);
      const response = await axios.get("/getImages", {
        params: {
          token: localStorage.getItem("access_token"),
          org: selectedOrg === null ? "default" : selectedOrg,
          fullTextSearch: value,
          limit: pageSize,
          offset: 0,
        },
      });
      setSearchResults(response.data);

      const getImagesCountRes = await axios.get("/getImagesCount", {
        params: {
          token: localStorage.getItem("access_token"),
          org: selectedOrg === null ? "default" : selectedOrg,
          fullTextSearch: value,
        },
      });
      console.log(getImagesCountRes.data);
      setTotalResults(getImagesCountRes.data);
    } catch (error) {
      console.error("Error handleSearch:", error);
    }
  };

  const handlePageChange = (page) => {
    const newOffset = (page - 1) * pageSize;
    setOffset(newOffset);
    setCurrentPage(page);
    fetchNext(newOffset);
  };

  const fetchNext = async (offset) => {
    try {
      const response = await axios.get("/getImages", {
        params: {
          token: localStorage.getItem("access_token"),
          org: selectedOrg === null ? "default" : selectedOrg,
          fullTextSearch: textSearch,
          limit: pageSize,
          offset: offset,
        },
      });
      setSearchResults(response.data);
      fetchImageCount();
    } catch (error) {
      console.error("Error fetching next images:", error);
    }
  };

  const fetchImageCount = async () => {
    const getImagesCountRes = await axios.get("/getImagesCount", {
      params: {
        token: localStorage.getItem("access_token"),
        org: selectedOrg === null ? "default" : selectedOrg,
        fullTextSearch: textSearch,
      },
    });
    console.log(getImagesCountRes.data);
    setTotalResults(getImagesCountRes.data);
  };

  const handleOrgChange = (option) => {
    setSelectedOrg(option);
    setCurrentPage(1);
    setOffset(0);
    setTextSearch("");
  };

  return (
    <Layout>
      <Header>
        <Navbar organization={organization} onOptionChange={handleOrgChange} />
      </Header>
      <Content>
        <div style={{ padding: "2rem", backgroundColor: "#1E2B37" }}>
          <div style={{ width: "50%", margin: "0 auto" }}>
            <Search
              id="searchInput"
              className="ant-search"
              placeholder="ค้นหา"
              onChange={(e) => {
                setCurrentPage(1);
                handleSearch(e.target.value);
                setTextSearch(e.target.value);
              }}
              suffix=""
              value={textSearch}
            />
          </div>
        </div>
        <ImageList searchResults={searchResults} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0.75rem",
          }}
        >
          <Pagination
            current={currentPage}
            onChange={handlePageChange}
            total={totalResults}
            pageSize={pageSize}
            showSizeChanger={false}
            showQuickJumper={false}
            showTotal={(total) => `Total ${total} items`}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default Home;
